.modal-dialog-right {
  padding: 8px;
  background-color: lime;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  height: 100vh;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  padding: 0;
}

.modal-dialog-right.show {
  padding: 0;
  z-index: 100;
  visibility: visible;
  opacity: 1;
  right: 0;
  width: 100%;
  // max-width: calc(100vw - 100px);
  // width: calc(100vw - 100px);
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
  .modal-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 35px;
    button {
      margin: 0;
    }
    .favorite {
      width: 300px;
    }
  }
  .modal-content-right {
    height: 100vh;
    margin-left: auto;
    width: calc(100% - 128px);
    background-color: $white;
    @media (max-width: 991px) {
      width: 100% !important;
    }
    .modal-body {
      height: 100%;
      padding: unset;
    }

    .library_detail {
      height: 100%;
      width: calc(100% - 315px);
      @media (max-width: 991px) {
        width: calc(100% - 315px) !important;
      }
      .library_detail_content {
        width: 100%;
        padding-bottom: 20px;
        height: 100%;
      }
    }
  }
  h5:hover {
    cursor: pointer;
  }
}
