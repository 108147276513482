.contaiTabCourse {
  padding-bottom: 20px;
  overflow-y: auto;
  @media (max-width: 991.98px) {
    overflow-y: auto;
    height: calc(100vh - 150px);
    padding: 30px;
  }
  .headerCourse {
    padding-left: 0px;
  }
  .contentTabCourse {
    margin-top: 20px;
    .titleTabCourse h4 {
      font-size: 22px;
    }
    .videoWrapper {
      border-radius: 20px;
    }
    .videoNullUrl {
      width: 100%;
      border-radius: 20px;
      height: 100%;
      background-color: rgb(94, 94, 94);
      position: absolute;
      top: 0;
      left: 0;
      p {
        color: rgb(201, 40, 40);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        width: fit-content;
        padding: 20px;
        border-radius: 20px;
        background-color: white;
      }
    }
  }
  .tabContentWrapper {
    margin-top: 20px;
    .lecture-curri {
      gap: 30px;
      border-bottom: 0.5px solid rgba(163, 178, 199, 0.5);
      h5 {
        font-size: 18px;
        margin: unset;
        padding: 10px;
        cursor: pointer;
      }
      .active {
        color: $header-bg;
        border-bottom: 2px solid $header-bg;
      }
    }
    .tabTextContent {
      margin-top: 30px;
      border-radius: 20px;
      box-shadow: $boxShadow;
      .contentLecture {
        padding: 0px 30px;
        max-height: 500px;
        overflow-y: auto;
        .message-link {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
