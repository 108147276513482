/* toastStyles.css */

/* Customize the border-radius for success toast */
.Toastify__toast--success {
  border-radius: 20px; /* Change this value to your desired border-radius */
}

.ReactOTPInput__input:focus {
  outline: none; /* This removes the default focus outline */
  border: none; /* Set the border to none */
  /* Add any other custom styles you want for the focused cell */
}

.infinite-scroll-component__outerdiv {
  position: relative;
}

.common_button {
  cursor: pointer;
  background: black;
  display: flex;
  border-radius: 24px;
  float: right;
  padding: 12px 20px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #F9C901;
  outline: none;
  font-weight: 700;
  border: none;
}

.common_button:hover {
  color: #fff;
  background-color: #6b4701 !important;
}
