*,
h4,
h5 {
  font-family: 'Nunito';
  color: #000;
}
.notifications-wrapper {
  margin: 0px !important;
  height: 100%;

  .modal-content {
    max-width: 430px;
    background: $white;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 0px;
    .notifications {
      height: calc(100% - 70px);
      .title_date_group {
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        margin-top: 30px;
      }

      .top_notification {
        justify-content: space-between;
        padding: 30px 30px 0px 30px;
        .mark_all_read {
          span {
            color: #f1b900;
            cursor: pointer;
            text-decoration: underline;
          }
          span:hover {
            color: $header-bg;
          }
        }
      }
    }
  }
  // .modal-dialog {

  // }
}
.overflow-noti {
  width: calc(100vw - 128px);
  height: calc(100vh - 70px);
  overflow: hidden !important;
  position: fixed;
  top: 70px;
  left: 128px;
  @media (max-width: 991.98px) {
    left: 0px;
    width: 100vw;
    height: calc(100vh - 150px);
  }
}
.backdrop-notification {
  position: fixed;
  width: calc(100vw - 128px);
  height: calc(100vh - 70px);
  top: 70px;
  left: 128px;
  z-index: 1040;
  background-color: #000;
  @media (max-width: 991.98px) {
    left: 0px;
    width: 100vw;
    height: calc(100vh - 150px);
  }
}
.notifications {
  .notifications_card_list {
    @media (max-width: 440px) {
      padding: 30px 10px;
    }
  }
  .card_wrapper {
    box-shadow: none;
  }
  flex-grow: 1;
  &_card_list:hover {
    overflow-y: auto;
  }
  &_card_list {
    flex-grow: 1;
    height: calc(100% - 80px);
    overflow: hidden;
    position: relative;

    padding: 10px 0px 30px 30px;
    h3 {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .card_wrapper:not(:last-child) {
      margin-bottom: 10px;
    }

    .card_wrapper {
      position: relative;
      max-width: 370px;
      background: $option_color;
      border-radius: 16px;
      display: flex;
      cursor: pointer;

      .notification_unread {
        width: 12px;
        height: 12px;
        border: 2px solid $white;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #03c988;
      }

      &:hover {
        background: #f7e9ad;
      }

      .card_left {
        display: flex;
        &_icon {
          width: 52px;
          height: 52px;
          border-radius: 14px;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .card_right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        &_title {
          font-size: 14px;
          font-weight: 700;
          padding-right: 10px;
        }

        &_name {
          font-size: 12px;
          font-weight: 500;
          width: 279px;
          color: #848484;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &_posted {
          img {
            margin-bottom: 3px;
            opacity: 0.6;
          }
          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #848484;
            display: inline-block;
            margin-left: 5px;
          }
        }

        .detail_btn {
          color: $black;
          border-radius: 30px;
          background: $yelow-700;
          width: 125px;
          line-height: 1;
        }
      }
    }

    .noti_unread {
      background: $gray-300;
    }
  }
}

.back-in-left {
  animation: backInLeft 1s;
}

@keyframes backInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
