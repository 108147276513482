.card_wrapper {
  background: $option_color;
  border-radius: 20px;
  display: flex;
  text-align: left;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background: $yelow-300;
  }

  .card_img {
    border-radius: 16px;
    img {
      width: 112px;
      height: 112px;
      border-radius: 16px;
    }
  }

  .card_left {
    flex-grow: 1;
    display: flex;
    padding: 10px;

    &_name {
      font-weight: 600;
    }

    &_posted {
      color: $gray-500;
      font-size: 12px;
    }

    .card_left_content {
      margin-left: 10px;
      color: #000;
      font-size: 14px;
      font-family: 'Nunito';
      font-style: normal;
      line-height: 22px;
      .card_left_title {
        font-weight: 400;
        opacity: 0.699999988079071;
      }
      .card_left_name {
        font-weight: 700;
        white-space: break-spaces;
      }

      .info_create {
        display: flex;
        margin-top: 12px !important;
        .avatarArthur {
          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            object-fit: cover;
          }
        }
      }

      .right_info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .nameArthur {
          vertical-align: text-top;
          line-height: 14px;
          font-weight: 600;
        }
      }

      .create_at {
        display: flex;
        align-items: center;
        span {
          margin-right: 16px;
        }
        div {
          display: flex;
          align-items: center;
          color: #707376;
          img {
            width: 18px;
            margin-right: 5px;
            opacity: 0.4;
          }
          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
  }

  .card_right {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card_right_freemium {
      position: absolute;
      top: 0px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .card_right_freemium_price {
        border-radius: 0px 16px;
        font-size: 12px;
        font-weight: 700;
        padding: 6px 16px;
      }
    }

    .detail_btn {
      font-size: 14px;
      width: 125px;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        margin-left: 8px;
      }
    }
  }
}
