.option-custom {
  margin: 30px 30px 0px 30px;
  border-radius: 30px;
  height: 46px;
  background-color: $option_color;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}

.select-input {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  border-radius: 30px;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.select-title {
  color: $gray-700;
}

.select-title.placeholder {
  color: $gray-600;
}

.questionWrapper {
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}

.list_question_content {
  display: inline-block;
}

.select-list {
  display: inline-block;
  background-color: $white;
  padding: 14px 20px 14px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
}

.select-item {
  border-radius: 20px;
  background: $option_color;
  padding: 5px 15px;
  margin: 10px 0px;
  font-size: 14px;
  font-family: 'Nunito';
  cursor: pointer;
}

.select-item:hover {
  background: $header_bg;
}

// .selected-list {
//   display: flex;
//   flex-wrap: wrap;
//   min-height: 42px;
//   width: 100%;
// }

// .selected-item {
//   padding: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   border: 1px solid #eee;
//   border-radius: 3px;
//   margin: 5px;
// }

// .selected-item span {
//   margin-right: 5px;
// }
