.user-chat {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  width: calc(100% - 486px);
  height: 100%;
  background-color: $white;
  z-index: 1;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
      .header_chat_list h5 img {
        display: inline;
        cursor: pointer;
      }
    }
  }
  .title_open_sidebar {
    max-width: calc(100vw - 950px) !important;
    @media (max-width: 991.98px) {
      max-width: calc(100vw - 380px);
    }
  }
  .right_open_sidebar {
    display: none !important;
  }
  .wrapper_audio {
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    // padding-right: 20px;
    min-width: 150px;
    .icon_playing {
      cursor: pointer;
    }
  }

  .empty_conversation {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty_image {
      padding: 33px;
      background-color: rgba(249, 201, 1, 0.1);
      border-radius: 50%;
      width: fit-content;
      margin-bottom: 20px;
    }
    .title_empty {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .header_chat_list {
    padding: 10px 30px;
    background-color: rgba(249, 201, 1, 0.10000000149011612);

    .leftHeader {
      font-size: 18px;
      font-family: Nunito;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      img {
        display: none;
      }
    }
    @media (max-width: 991px) {
      .leftHeader {
        display: flex;
        img {
          display: inline;
          cursor: pointer;
          transform: translateY(-4px);
        }
      }
    }
  }
}
.edit_drop_down {
  left: -160px !important;
}
.edit_drop_down[x-placement^='top'] {
  transform: translate(0px, -50px) !important;
}
.update_convert_role.edit_drop_down {
  left: -230px !important;
}
.user-chat-nav {
  .nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    color: $gray-600;
  }
}

.chat-conversation {
  background-color: $white;
  padding: 20px 20px;

  li {
    clear: both;
  }

  .chat-avatar {
    margin-right: 16px;
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: $border-color;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-top: 30px;
    margin-bottom: 24px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    max-width: 70%;

    .user-chat-content {
      padding: 14px 20px;
      background-color: $option_color;
      border-top-left-radius: 47px;
      border-top-right-radius: 47px;
      border-bottom-right-radius: 47px;
      position: relative;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      .card_unset {
        background-color: unset;
      }
      p {
        font-size: 14px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .time_user_chat {
        position: absolute;
        margin-top: 4px;
        top: 100%;
        left: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        white-space: nowrap;
      }
    }

    .conversation-name {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-time {
      font-size: 12px;
      text-align: right;
      color: $gray-600;
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        max-width: 150px;
      }

      .message-img-link {
        position: absolute;
        right: 10px;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: $white;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }
  .conversation-list.empty_time {
    margin-bottom: 0px;
  }

  .right {
    .conversation-list.empty_time {
      margin-bottom: 5px;
    }
    .wrapper_audio {
      .progress-container {
        background-color: #848484;
      }
      .progress-bar {
        background-color: #000;
      }
      .circle_after {
        background-color: #000;
      }
    }
    .chat-avatar {
      order: 3;
      margin-right: 0px;
      margin-left: 16px;
    }

    .chat-time {
      text-align: right;
      // color: $gray-600;
    }

    .conversation-list {
      float: right;
      .user-chat-content {
        background-color: $header_bg;
        border-bottom-left-radius: 47px;
        border-bottom-right-radius: 0px;
        position: relative;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        p {
          .message-link {
            color: #1791f5;
          }
        }
        p {
          .message-link:hover {
            color: #0684eb;
          }
        }

        .time_user_chat {
          top: 100%;
          right: 0;

          display: flex;
          justify-content: flex-end;
        }
      }

      .dropdown {
        order: 1;
      }
    }

    .dot {
      background-color: $dark;
    }
  }
}
.name_sender_chat {
  margin-bottom: 0px;
  font-weight: 700;
  opacity: 0.5;
}

.chat-conversation {
  height: calc(100vh - 240px) !important;
  @media (max-width: 991.98px) {
    height: calc(100vh - 192px);
  }
}

.chat-input-links {
  .list-inline-item:not(:last-child) {
    margin: 0;
  }
}

.wrapper_add_chat {
  padding: 10px 30px 20px 30px;
  z-index: 1;
  position: relative;
  @media (max-width: 991.98px) {
    padding: 10px 20px 20px 20px;
  }
  .image_show_input {
    position: absolute;
    bottom: 100%;
    border-radius: 5px;
    border: 3px solid rgb(105, 103, 103);
    .close-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
    .item_img {
      max-width: 150px;
      height: 120px;
      object-fit: cover;
    }
  }
  .button_contai {
    display: flex;
    align-items: center;
    margin-right: 3px;
  }
  button {
    border: none !important;
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
    background-color: $option_color;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .contai_input_btn {
    border-radius: 23px;
    background-color: $option_color;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
    .mic-wrapper {
      box-shadow: unset;
      width: 320px;
      justify-content: space-between;
    }
    .wrapper_audio {
      margin: unset;
      .icon_playing img {
        height: 20px;
      }
    }
  }
  .recording_mic.mic-wrapper {
    width: unset !important;
  }

  .mic-wrapper {
    position: relative;
    margin-left: 7px;
    background: $option_color;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
    display: flex;
    align-items: center;
    max-height: 50px;
    .action_voice {
      background: $option_color;
      position: absolute;
      bottom: 100%;
      right: 0px;
      display: flex;
      border-radius: 25px;
      padding: 5px;
      gap: 5px;
      .delete_voice {
        position: absolute;
        bottom: 100%;
        right: 0px;
        transform: translateY(50%);
        img {
          height: 10px;
        }
      }
    }
    .timeCount {
      margin: 0px 5px;
    }
    .contai-img {
      position: relative;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      width: 40px;
    }
    .trash_icon.contai-img {
      background-color: unset;
    }
    .img-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
}

.chat-read {
  position: relative;
  top: 5px;
  right: 5px;
  font-size: 15px;
  &.seen {
    color: #4fc3f7;
  }
}
.chat-input-wrapper {
  min-height: 45px;
  overflow: hidden;
  padding: 5px 30px;
  display: flex;
  align-items: center;

  .chat-input-compose {
    // display: flex;
    // align-items: center;
    font-size: 15px;
    font-weight: 400;
    max-height: 80px;
    // min-height: 35px;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    &[placeholder]:empty:before {
      content: attr(placeholder);
      color: #555;
    }
    width: 100%;
  }
}

.multiple_choice.SINGLE {
  box-shadow: 0 0 0 1pt rgb(86, 86, 86);
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  width: 12px;
  -webkit-appearance: none;
}
.mul-warpper.SINGLE input:checked {
  border: 2px solid #fff;
  background-color: #f9c901;
  box-shadow: 0 0 0 1pt #f9c901;
  opacity: 1;
  box-sizing: border-box;
  outline: none;
}
.mul-warpper input[type='checkbox'] {
  accent-color: #f9c901;
}
.mul-warpper label {
  margin-left: 10px;
}
.title_quest {
  margin-bottom: unset;
  font-weight: 700 !important;
}
.submit_answer {
  padding: 3px 10px;
  border-radius: 12px;
  font-size: 10px;
  background-color: #c5c5c5;
  color: #000;
}
.submit_answer:hover {
  background-color: #9a9a9a;
  color: #fff;
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: -1px;
    background: $white;
    animation: wave 1.3s linear infinite;
    opacity: 0.6;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

.vocabulary_wrapper {
  ul {
    margin: unset;
    padding: unset;
    li {
      list-style-type: none;
    }
  }
  .list_vocabulary {
    .item_vocabulary {
      .word_wrap {
        display: flex;
        gap: 5px;
        .list_icon {
          vertical-align: center;
        }
      }
      .action_item {
        display: inline;
        cursor: pointer;
        margin-top: 0px;
      }
      .vocab_word {
        margin: unset;
      }
      .vocab_word_vi {
        font-weight: 500;
      }
      .note_vocab {
        margin-bottom: unset;
      }
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}

@media (min-width: 992px) {
  .emoji-dropdown {
    .dropdown-menu-lg-right {
      right: 0 !important;
      left: '-82px' !important;
      width: 360px !important;
    }
  }
}
.warning_character_null:hover {
  cursor: default;
}

.chat-label {
  display: inline;
  color: #fff;
  font-size: 10px;
  border-radius: 9px;
  padding: 5px;
  &.company {
    background-color: $cyan;
  }
  &.candidate {
    background-color: $yellow;
  }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
}

// animation ellipsis
.simplebar-content {
  min-height: 100%;
  position: relative;
}
#listMessages {
  min-height: 100%;
}
.item-chatGPT-sending {
  position: absolute;
  left: 10px;
  bottom: 4px;
}
.chatGPT-sending {
  display: flex;
  align-items: flex-end;

  .avatar-loading {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.5px solid rgb(120, 120, 120);
    img {
      height: 16px;
    }
  }
  .host {
    background: #fff;
    width: 70px; /* 4 dots * (10px size + 5px spacing) */
    height: 20px;
    margin-left: 20px;
  }

  .loading {
    width: 10px;
    height: 10px;
    background: $header_bg;
    border-radius: 100%;
    float: left;
    margin-right: 5px;
    margin-top: 10px;
  }

  .loading-0 {
    animation: bounce 1s infinite;
    animation-delay: 0.1s;
  }

  .loading-1 {
    animation: bounce 1s infinite;
    animation-delay: 0.3s;
  }

  .loading-2 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.5s;
  }

  .loading-3 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.7s;
  }

  @keyframes bounce {
    0%,
    100% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
  }
}
.modal_confirm_group {
  .modal-content {
    max-width: 400px;
    padding-bottom: 20px;
    border-radius: 30px;
  }
}
