.help_center_contai,
.common_icon_button {
  position: relative;
  cursor: pointer;
  background: #fff;
  display: flex;
  border-radius: 30px;
  padding: 14px 18px;
  float: right;
  margin-right: 5px;
  max-width: 250px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  .noti-helper {
    top: 0px;
    right: 0px;
    transform: translateY(-50%);
    color: #fff;
  }
  .left_button {
    width: fit-content;
    margin: 0px 20px 0px 10px;
  }
}
.unReadHelp {
  background-color: $header_bg;
}

.edit_profile_button {
  padding: 10px 20px 10px 20px;
  margin-top: 8px;
  float: left;
  background-color: #f6f6f6;
}

.left_button {
  position: relative;
  width: 40px;
}

.content_left_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  // background: #f95959;
  // background-color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
}
.right_button {
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.setting_helpCenter:hover img {
  filter: brightness(0) invert(1);
}
.content_right_button {
  font-size: 8px;
}
.setting_helpCenter {
  padding: 14px 18px !important;
}

.modal-help-contai {
  max-width: unset;
}

.modal-help-contai {
  max-width: 750px;
  .modal-content {
    max-width: 100%;
    padding: 0px;
    height: 80vh;
    background-color: red;
    border-radius: 30px;
    .modal-body {
      padding: 0px;
      width: 100%;
      position: relative;
      h5 a {
        color: #000 !important;
      }
      .iconCloseModal {
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;
      }

      .user-chat {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        border: 1px solid var(--text-02, #a3b2c7);
        .header_chat_list {
          background-color: $header_bg;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          .header-chart-wrapper {
            justify-content: center !important;

            img {
              display: none;
            }
          }
        }
      }
      @media (max-width: 991.98px) {
        .user-chat {
          position: unset !important;
          transform: translateX(0px);
          visibility: unset;
        }
      }

      .contai-profile-item {
        height: 100%;
        width: 100%;
        .chat-conversation {
          height: calc(100% - 125px);
        }
      }
    }
  }
}
