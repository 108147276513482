.progress-container {
  border-radius: 2px;
  width: 100%;
  height: 4px;
  background-color: #ccc;
  cursor: pointer;
  position: relative;
  overflow: visible;
  .time_played {
    position: absolute;
    top: 100%;
    margin-top: 5px;
  }
}
.progress-bar {
  height: 4px;
  width: 0;
  background-color: $header_bg;
  overflow: visible;
  position: relative;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  .circle_after {
    position: absolute;
    left: 100%;
    background-color: $header_bg;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
}
.wrapper_audio {
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  .icon_playing {
    cursor: pointer;
  }
}
.progress-container.is_input {
  width: 100px;
  .time_played {
    left: 100%;
    top: 100%;
    transform: translate(10px, -18px);
  }
}
.audio_wrp_play {
  border-radius: 25px !important;
  width: 200px !important;
  background-color: #fff !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
  .wrapper_audio {
    padding-right: 35px;
    padding-left: 10px;
  }
}
