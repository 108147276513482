.seclectTab_line {
  background-color: #a3b2c7;
  height: 0.5px;
  overflow: visible;
  margin-top: 25px;
  .wraper_select{
    border: 1px solid #a3b2c7;
    height: fit-content;
    border-radius: 10px;
    transform: translateY(-50%);
    background-color: #fff;
    .right_select {
      border-left: 1px solid #a3b2c7;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .left_select {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
    .item_select{
      padding: 5px 15px;
      color: #000;
      background-color: #fff;

      cursor: pointer;
    }
    .item_select:hover{
      background-color: #f1f0f0;
    }
    .item_select.active{
      color: #fff;
      background-color: #b7b7b7;
    }
    .item_select.active:hover{
      background-color: #959595;
    }
  }
}
.contai_traking{
  .title_option{
    background-color: #f6f6f6;
    width: 100%;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    outline: none !important;
  }
  .title_option:active{
    background-color: #f6f6f6 !important;
  }
  .title_option:focus{
    box-shadow: none !important;
  }
  .menu_title {
    min-width: 164px;
  }
  .item_option:hover {
    background-color: #e9e9e9;
  }
}
