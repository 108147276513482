//
//  User profile details.scss
//

// User profile details

.user-profile-sidebar {
  height: 100%;
  background-color: $card-bg;
  display: none;
  min-width: 380px;
  max-width: 380px;
  @media (max-width: 1254px) {
    min-width: 271px;
    max-width: 380px;
    width: 100%;
  }
  .img_pro_left {
    max-width: 100px;
    max-height: 100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #c9c9c9;
  }
  .user_gr {
    h5 {
      margin: unset;
      margin-top: 2px;
    }
    justify-content: space-between;
  }
  .rename_gr {
    cursor: pointer;
    .card-header {
      border: 1px solid #f0eff5;
      border-radius: 10px;
    }
  }
  .ps_avatar {
    .wrapper_cam {
      bottom: 0px !important;
      right: 0px !important;
      transform: translate(10%, 40%);
    }
  }
  .searchIcon {
    position: absolute;
    left: 14px;
    cursor: pointer;
    height: 16px;
  }
  img.searchIcon {
    margin-top: 8px;
  }
  div.searchIcon {
    margin-top: 0px;
  }
  .search_member {
    border-radius: 20px;
    outline: none;
    border: 1px solid #a6a6a6;
  }
  @media (min-width: 380px) {
    border-left: 4px solid $border-color;
  }
  @media (max-width: 1145px) {
    position: fixed;
    right: 0;
    z-index: 99;
    width: calc(100vw - 360px - 128px);
    max-width: calc(100vw - 360px - 128px);
  }

  @media (max-width: 991px) {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 575.98px) {
    min-width: 100%;
  }
}
.searchIcon.isGroup {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  right: 14px;
  left: unset;
}

.user-profile-desc {
  height: calc(100vh - 385px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 324px);
  }
}

.option_select_group {
  margin-top: 10px;
  list-style-type: none;
  padding: 25px 20px 15px 20px;
  border-radius: 20px;
  background-color: rgba(165, 165, 165, 0.3);
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 180px;
  position: relative;
  .suggested {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 12px;
    color: #ffffff;
    background-color: rgb(199, 199, 199);
    border-radius: 20px 0px;
    padding: 0px 10px;
  }
  .option_select_item {
    vertical-align: center;
    width: fit-content;
    text-align: start;
    input {
      opacity: 0;
      height: 1px;
      width: 1px;
      display: none;
    }
    label {
      background-color: #fff;
      padding: 5px 15px;
      border-radius: 20px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      white-space: nowrap;
    }
    .longTextLabel {
      white-space: normal;
    }
    label:hover {
      background-color: $header_bg;
    }
    input[type='radio']:checked + label {
      background: $header_bg;
    }
  }
}
