.community-wrapper {
  margin: 0px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 0px;
  position: relative;
  .scroll_wrapper,
  .detail-wrapper,
  .create_btn_wrapper {
    max-width: 680px;
    margin: 0px auto;
    @media (min-width: 1350px) {
      max-width: 800px;
    }
    @media (min-width: 1450px) {
      max-width: 900px;
    }
  }
  .contai_btn_create {
    width: 100%;
    position: sticky;
    left: 0px;
    top: 100%;
    z-index: 999;
    .create_btn_wrapper {
      position: relative;
      .btn_create_post {
        position: absolute;
        left: 100%;
        bottom: 20px;
      }
      @media (max-width: 1090px) {
        width: 100%;
        max-width: 100%;
        .btn_create_post {
          right: 10px;
          bottom: 10px;
          left: unset;
        }
      }
    }
  }

  .infinite-scroll-component__outerdiv {
    padding: 0px 10px;
  }
  .title_top-wrapper {
    border-radius: 10px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 4px 4px 0px 4px;
    p {
      margin-bottom: unset;
      span {
        cursor: pointer;
      }
    }
  }

  .create-post-section {
    width: 100%;
    display: flex;

    .avatar {
      margin-right: 16px;
      border: 5px solid #f6f6f6;
      border-radius: 50%;
    }

    .create-post-button {
      height: 46px;
      width: 100%;
      text-align: left;
      border-radius: 24px;
      background-color: #f6f6f6;
      color: #000 !important;
      border: unset;
      box-shadow: 0px 6px 10px 0px #00000026;
      outline: none;
      position: relative;
      padding-left: 50px;
      .input_search_post {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        border-radius: 24px;
        background-color: #f6f6f6;
      }
      .icon_search_post {
        position: absolute;
        left: 24px;
        margin-top: 12px;
        cursor: pointer;
      }
    }

    .icon-filter-button {
      height: 46px;
      margin-left: 16px;
      border-radius: 12px;
      color: black !important;
      background-color: #f9c901;
      box-shadow: 0px 6px 10px 0px #00000026;

      .filter-icon {
        font-size: 20px;
        margin: 0 4px;
      }
    }
    .icon-filter-button:focus {
      border-color: none;
    }
  }

  .post-section {
    .wraperContentCommu {
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      margin: 20px 0px;
    }
    .content {
      border-radius: 12px;
      background: #f6f6f6;
    }

    .user-info {
      display: flex;
      align-items: center;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .dropdown-menu-wrapper {
      .dropdown-menu-custom {
        margin-right: 10px;
        border-radius: 20px;
        .dropdown-item-custom {
          display: flex;
          align-items: center;
        }
        .dropdown-item-custom:last-child {
          color: #e76161;
        }
      }
    }

    .post-images {
      display: grid;
      grid-gap: 10px;

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 14px;
      }

      .post-images-item-1 {
        max-height: 308px;
      }
    }

    .post-images-3 {
      display: grid;
      grid-template-columns: calc(66% - 5px) calc(34% - 5px);
      grid-template-rows: calc(50% - 5px) calc(50% - 5px);
      grid-gap: 10px;

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 14px;
      }

      .post-images-item-1 {
        grid-column: 1 / 2;
        grid-row: 1 / span 2;
      }

      .post-images-item-2 {
        grid-column: 2 / 2;
        grid-row: 1 / 2;
      }

      .post-images-item-3 {
        grid-column: 2 / 2;
        grid-row: 2 / 2;
        position: relative;
      }

      .image-background-opacity {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.4;
        background: black;
        z-index: 1;
        border-radius: 14px;
      }

      .image-camera-white-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
    .post-images-2 {
      display: grid;
      grid-gap: 10px;

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 14px;
      }

      .post-images-item-1-2 {
        grid-column: 1 / 1;
        grid-row: 1 / span 2;
      }

      .post-images-item-2-2 {
        grid-column: 2 / 2;
        grid-row: 1 / span 2;
      }
    }
    .post-action {
      display: flex;
      align-items: center;
      .share_post {
        margin-left: 20px;
        .btn_share_post {
          padding: 8px 12px;
          font-size: 10px;
          border: unset;
        }
      }
      .action-icon-background {
        height: 28px;
        width: 28px;
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .heart,
      .comment {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
        }
      }
    }
  }

  .post-comment {
    width: 100%;
    display: flex;

    .avatar {
      margin-right: 16px;
      border-radius: 50%;
    }

    form {
      width: 100%;
    }

    .comment-input-group {
      border-radius: 4rem;
      padding: 7px 0px;
      background-color: #fff;
      .input-custom {
        border-left-width: 0;
        border-right-width: 0;
        border-top-left-radius: 4rem;
        border-bottom-left-radius: 4rem;
        padding: 0px 0px 0px 40px !important;
        border: unset;
        resize: none;
        max-height: 80px;
        overflow-y: auto;
        margin-top: 7px;
        margin-right: 10px;
      }

      .input-custom:focus {
        box-shadow: unset !important;
      }

      .addon-right-custom {
        background-color: #fff;
        border-top-right-radius: 4rem !important;
        border-bottom-right-radius: 4rem !important;
        cursor: pointer;
        border: unset;

        .image-comment {
          .input-upload-image {
            display: none;
          }

          .upload-icon-button {
            cursor: pointer;
          }
        }
        .comment-submit-button {
          border-radius: 50%;
          width: 34px;
          height: 34px;
        }
      }
    }
  }
  .comment-images-wrapper {
    margin-left: 62px;

    .comment-images {
      position: relative;
      display: inline-block;
      margin-right: 16px;
      .item {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        object-fit: cover;
      }
      .close-icon {
        position: absolute;
        right: -8px;
        top: -8px;
        cursor: pointer;
      }
    }
  }

  .comment-list,
  .comment-reply-list {
    .comment-header {
      display: flex;
      justify-content: space-between;
      .user-info-comment {
        display: flex;
        align-items: center;
        .avatar {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          object-fit: cover;
        }
      }
    }

    .comment-content {
      padding-left: 40px;
      padding-right: 0px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      .image-comment-list {
        display: flex;
        gap: 8px;
        .image-comment-item {
          border-radius: 6px;
          width: 168px;
          height: 168px;
          object-fit: cover;
          margin-top: 8px;
        }
      }
      .reply-button {
        cursor: pointer;
        font-weight: 700;
        color: #f9c901;
      }
    }
  }
}
.community-wrapper.post_non {
  .wraperContentCommu {
    margin: 0px;
    .post-action {
      .heart {
        cursor: unset !important;
        img {
          cursor: unset !important;
        }
      }
    }
  }
  height: calc(100vh - 130px);
}

.create-post-modal,
.filter-post-modal {
  .modal-title {
    font-weight: 700;
  }
  .modal-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      margin-left: 0;
      position: absolute;
      right: 32px;
    }
  }
  .modal-content {
    width: 450px;
    border-radius: 40px;
  }
  .post-title-icon {
    background: white;
    border-bottom: 0.5px solid #a3b2c7;
  }

  .image-wrapper {
    display: flex;

    .input-upload-image {
      display: none;
    }
    .image-item {
      position: relative;
      .item {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        object-fit: cover;
      }
      .close-icon {
        position: absolute;
        right: -8px;
        top: -8px;
        cursor: pointer;
      }
    }
    .contai_list_file {
      flex-grow: 1;
    }
  }

  .input-group-text-custom {
    border: none;
    border-radius: 0 !important;
    border-bottom: 0.5px solid #a3b2c7;
    background-color: #fff;
  }
  .input-post-custom.is-invalid {
    border: none;
    border-bottom: 0.5px solid #a3b2c7;
  }
  .input-post-custom.is-invalid:focus {
    box-shadow: none;
  }
  .input-post-custom {
    border: none;
    border-radius: 0;
    border-bottom: 0.5px solid #a3b2c7;
  }

  .textarea-custom {
    border-radius: 20px !important;
    background-color: #f6f6f6;
  }

  .icon-upload-button {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
}

.loading_post .loader {
  margin: unset;
  position: absolute;
  top: 40%;
  left: 50%;
}

.text_top_modal {
  @media (min-width: 576px) {
    max-width: 750px;
  }
  .modal-content {
    border-radius: 20px;
  }
  .modal-header {
    padding: unset;
    padding-right: 20px;
    padding-top: 10px;
  }
}

.input-post-custom:-webkit-autofill,
.input-post-custom:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

//
.checked-button-filter:has(> input[type='checkbox']:checked) {
  background: $header_bg;
}
.checked-button-filter {
  display: inline-flex;
  align-items: flex-start;
  margin: 3px;
  border-radius: 20px;
  background: $option_color;
  padding: 6px 0px;
  padding-right: 15px;
  cursor: pointer;

  label {
    margin-left: -15px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  input[type='checkbox'] {
    opacity: 0;
  }
}

//

.post-sort-by {
  margin-top: 10px;
  // padding: 0px 30px;
  input[type='radio'] {
    opacity: 0;
  }
  .radio-custom-wrapper {
    border-radius: 20px;
    padding: 6px 14px;
    display: inline-block;
    margin: 3px;
    background: $option_color;
  }
  .radio-custom-wrapper:hover {
    background: $header_bg;
  }
  .radio-custom-wrapper:has(> input[type='radio']:checked) {
    background: $header_bg;
  }
  label {
    border-radius: 20px;
    margin-left: -30px;
    cursor: pointer;
  }
}

.post-filter-action {
  display: flex;
  justify-content: center;
  gap: 8px;

  .apply-btn,
  .reset-btn {
    padding: 12px 48px;
  }
}

.footer_post_non {
  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  .filter-action {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

// Responsive
.community-wrapper.container {
  @media (min-width: 768px) {
    max-width: unset;
  }
}
@media (min-width: 992px) {
  .community-wrapper {
    max-width: calc(100% - 128px);
  }
}
@media (max-width: 991px) {
  .community-wrapper {
    max-width: 100%;
    height: calc(100vh - 150px);
  }
}
