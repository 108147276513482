.header_page {
  background-color: $header_bg;
  position: relative;
  overflow: hidden;
  .circle_contai {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffe267;
    height: 232px;
    width: 232px;
    border-radius: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header_logo_pombeebee {
      width: 170px;
      height: 60px;
      flex-shrink: 0;
    }
  }
  .wrapper_join_club {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.joinClub-wrapper {
  width: 430px;
  margin: 0px;
  height: 100%;
  .modal-content {
    height: 100% !important;
    @media (max-width: 991.98px) {
      width: 100vw;
    }
  }
}
.overflow-joinClub {
  width: 430px;
  right: 0px;
  left: unset;
  top: 70px;
  height: calc(100vh - 90px);
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  @media (max-width: 991.98px) {
    width: 100vw;
    height: calc(100vh - 150px);
  }
}
.backdrop-joinClub {
  width: 430px;
  right: 0px;
  left: unset;
  top: 70px;
  height: calc(100vh - 90px);
  border-radius: 50px;
  @media (max-width: 991.98px) {
    width: 100vw;
    height: calc(100vh - 150px);
  }
}
