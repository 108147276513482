.contai-profile {
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;

  .profile-help {
    position: absolute;
    bottom: 20px;
    right: 20px;
    @media (max-width: 991.98px) {
      bottom: 90px;
    }
  }

  .library {
    height: calc(100vh - 300px);
    .labrary_question {
      height: calc(100vh - 330px);
      margin: 10px 0px;
      margin-top: 30px;
    }
  }
  .tracking_wrapper {
    height: calc(100vh - 400px);
    position: relative;
    .recharts-tooltip-item-name,
    .recharts-tooltip-item-separator,
    .recharts-tooltip-item-value,
    .recharts-tooltip-item-unit {
      color: inherit;
    }
    .empty_wrapper {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.profile-user-page {
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  padding: 30px 20px 0px 20px;

  .profile-content {
    .username {
      margin-bottom: 10px;
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .profile-contai-info {
      height: 171px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .profile-content__info {
        justify-content: space-between;
        width: 100%;
        margin-left: 30px;
        .profile-info-item {
          display: flex;
          label {
            width: 80px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            margin: 0px;
          }
          h5 {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            margin: 0px;
          }
        }
      }

      .profile-info-left {
        display: flex;
        height: 140px;
        align-items: flex-end;
        padding: 16px 25px;
        background-color: $option-color;
        border-radius: 20px;
        .profile-contai-content {
          display: flex;
          justify-content: space-between;
          .listBtn-right {
            display: flex;
            align-items: flex-end;
            .dropdown-toggle {
              padding: 0px;
            }
            .dropdown-toggle {
              border: none;
              outline: none !important;
              background-color: transparent;
              color: #000 !important;
            }
            .dropdown-toggle:focus {
              box-shadow: none !important;
            }
            .list-listAction {
              padding: 11px 0px !important;
            }
            .item-listAction {
              border-radius: 0px;
              margin: 0px;
              padding: 11px 20px;
            }
            .item5 p,
            .item7 p {
              color: #e76161;
            }
          }
        }
      }
    }
    .profile-content__img {
      .img-wrapper {
        border-radius: 155px;
        border: 7.75px solid $option-color;
        margin-bottom: -7px;
        width: fit-content;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        img {
          height: 155px;
          width: 155px;
          border-radius: 155px;
        }
      }
    }
  }
}

.modal-language {
  .modal-content {
    width: 70%;
    margin: auto;
  }
}

.modal-avatar {
  .card-body {
    & > div {
      img {
        width: 100%;
      }
    }
  }
}

.icon-wrapper i {
  color: #fff;
}
